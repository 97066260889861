import { ElementType, IElement } from "../../../models/Element";

export type MarginTopElement = IElement & {
	unit: string;
};

export const defaultMarginTopElement: MarginTopElement = {
	id: 0,
	name: ElementType.UTILITY_MARGIN_TOP,
	unit: "10px",
};
