import { ElementType, IElement } from "../../../models/Element";
import { ElementHeaderType } from "../../utils";

export type BlogContentWideDescription = IElement & {
	title: string;
	title_type: ElementHeaderType;
	content: string;
};

export const defaultBlogContentWideDescription: BlogContentWideDescription = {
	id: 0,
	name: ElementType.BLOG_CONTENT_WIDE_DESCRIPTION,
	title: "Koppeling met Google Analytics",
	title_type: ElementHeaderType.H2,
	content:
		"<p>Cras justo odio, dapibus ac facilisis in, egestas eget quam. Nullam id dolor id nibh ultricies vehicula ut id elit. Donec ullamcorper nulla non metus auctor fringilla. Etiam porta sem malesuada magna mollis euismod. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Sed posuere consectetur est at lobortis. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec ullamcorper nulla non metus auctor fringilla. Maecenas faucibus mollis.</p>",
};
