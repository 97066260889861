import { PageDataContainer, PageLink } from "../../../_start/layout/core";
import { mediaManagementSubmenu } from "./MediaManagementData";
import { MediaManagementPage } from "./MediaManagementPage";

import './_media-library.scss';

const breadcrumbs: Array<PageLink> = [
	{
		title: "Home",
		path: "/",
		isActive: false,
	},
	{
		title: "Media Management",
		path: "/media-management",
		isActive: false,
	},
];

export const MediaManagementWrapper = () => {
	return (
		<>
			<PageDataContainer
				breadcrumbs={breadcrumbs}
				submenu={mediaManagementSubmenu}
			/>
			<MediaManagementPage />
		</>
	);
};
