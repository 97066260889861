import { KTSVG } from "../../../../_start/helpers";

type RemoveElementButtonProps = {
	index: number;
	remove: (index: number) => void;
	buttonRef: React.RefObject<HTMLButtonElement>;
};

export const RemoveElementButton = ({
	index,
	remove,
	buttonRef,
}: RemoveElementButtonProps) => {
	return (
		<span
			onClick={() => remove(index)}
			ref={buttonRef}
			className="position-absolute top-0 end-0 d-none me-n5 mt-n5 opacity-75-hover"
			style={{
				zIndex: "2",
			}}
		>
			<KTSVG
				path="/media/icons/duotone/Interface/Minus-Square.svg"
				className="svg-icon-danger svg-icon-2hx"
			/>
		</span>
	);
};
