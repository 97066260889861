import { ElementType, IElement } from "../../../models/Element";

export type MarginBottomElement = IElement & {
	unit: string;
};

export const defaultMarginBottomElement: MarginBottomElement = {
	id: 0,
	name: ElementType.UTILITY_MARGIN_BOTTOM,
	unit: "10px",
};
