export enum TitleSeparatorType {
	DASH = "-",
	EN_DASH = "–",
	EM_DASH = "—",
	COLON = ":",
	SMALL_BULLET = "·",
	MEDIUM_BULLET = "•",
	ASTERISK = "*",
	STAR = "⋆",
	BAR = "|",
	TILDE = "~",
	DOUBLE_LEFT_ARROW = "«",
	DOUBLE_RIGHT_ARROW = "»",
	LEFT_ARROW = "<",
	RIGHT_ARROW = ">",
}

export interface ISetting {
	id: number;
	title_separator: string;
}

export const defaultSetting: ISetting = {
	id: 0,
	title_separator: "",
};
