import { PaginatedValue } from "../../../support/utils";
import { IMedia } from "../models/Media";
import { MediaLibraryActions } from "./MediaLibraryAction";
import { MediaLibraryEvents } from "./MediaLibraryEvents";

export interface IMediaLibraryState {
	initialized: boolean;
	paginatedValue: PaginatedValue<IMedia>;
}

const defaultMediaLibraryState: IMediaLibraryState = {
	initialized: false,
	paginatedValue: new PaginatedValue(),
};

export const reducer = (
	state: IMediaLibraryState = defaultMediaLibraryState,
	action: MediaLibraryActions
): IMediaLibraryState => {
	switch (action.type) {
		case MediaLibraryEvents.SET_PAGINATED_VALUE:
			return {
				...state,
				initialized: true,
				paginatedValue: action.payload,
			};

		case MediaLibraryEvents.SET_SEARCH_PAGINATED_VALUE:
			return {
				...state,
				paginatedValue: action.payload,
			};

		default:
			return state;
	}
};
