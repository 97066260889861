/* eslint-disable jsx-a11y/anchor-is-valid */

import { PaginatedValue } from '../support/utils';

type PaginationProps = {
  loading?: boolean;
  pagination: PaginatedValue;
  handlePaginateState: (page_url: string) => Promise<void>;
};

export const Pagination = ({
  loading = false,
  pagination,
  handlePaginateState,
}: PaginationProps) => {
  return (
    <ul className="pagination">
      <li
        onClick={() => pagination.prev_page_url && handlePaginateState(pagination.prev_page_url as string)}
        className={`page-item previous ${
          pagination.prev_page_url ? '' : 'disabled'
        } ${loading ? 'disabled' : ''}
        `}
      >
        <a className="page-link">
          <i className="previous"></i>
        </a>
      </li>
      {pagination.links.map((item, idx) => {
        if (idx === 0 || idx === pagination.links.length - 1) return '';

        return (
          <li
            onClick={() => !item.active && handlePaginateState(item.url as string)}
            key={idx}
            className={`page-item ${item.active ? 'active' : ''} ${
              loading ? 'disabled' : ''
            }`}
          >
            <a className="page-link">{item.label}</a>
          </li>
        );
      })}
      <li
        onClick={() => pagination.next_page_url && handlePaginateState(pagination.next_page_url as string)}
        className={`page-item next ${
          pagination.next_page_url ? '' : 'disabled'
        } ${loading ? 'disabled' : ''}`}
      >
        <a className="page-link">
          <i className="next"></i>
        </a>
      </li>
    </ul>
  );
};
