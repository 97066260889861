type SuccessToastProps = {
	message: string;
	title?: string;
};

export const SuccessToast = ({
	message = "",
	title = "Successfully saved!",
}: SuccessToastProps) => {
	return (
		<div className="d-inline-flex">
			<div className="toast-header">
                <i className="bi bi-check-circle fs-2x text-primary"></i>
			</div>
			<div className="ms-1 d-flex flex-column toast-body">
				<h6 className="text-sm font-semibold text-gray-800">
					{title}
				</h6>
				<span className="text-xs text-gray-600 tracking-wide">
					{message}
				</span>
			</div>
		</div>
	);
};

