import { EditContentItemType, IElement } from "../models/Element";
import { useDrag } from "react-dnd";

type SidebarElementDragProps = {
	element: IElement;
};

export const SidebarElementDrag = ({ element }: SidebarElementDragProps) => {
	const [{ isDragging }, drag] = useDrag(() => ({
		type: EditContentItemType.ELEMENT_INSERT,
		end: (item, monitor) => {},
		item: element,
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	}));
	return (
		<div
			id="sidebar-insert-drag-element"
			ref={drag}
			style={{
				opacity: isDragging ? 0.5 : 1,
			}}
			className="d-flex align-items-center py-2 sidebar-drag-component"
		>
			<span className="bullet bg-primary me-5"></span>
			<span>{element.name}</span>
		</div>
	);
};
