export interface IMedia {
	id: number;
	uuid: string;
	order: number;
	name: string;
	custom_properties: {
		[key: string]: any;
	};
	preview_url: null | string;
	original_url: null | string;
	extension?: string;
	size?: number;
	mime_type: string;
}

export const defaultMedia: IMedia = {
	id: 0,
	uuid: "",
	order: 0,
	name: "",
	custom_properties: {},
	preview_url: "",
	original_url: "",
	extension: "",
	size: 0,
	mime_type: "",
};
