/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

type StaticWidthInlineEditProps = {
  name: string;
  value: string;
  setValue: (fieldsToUpdate: Partial<unknown>) => void;
  className?: string;
  style?: React.CSSProperties;
  activeVerticalPadding?: string;
};

export const StaticWidthInlineEdit: React.FC<StaticWidthInlineEditProps> = ({
  name,
  value,
  setValue,
  className,
  style,
  activeVerticalPadding = "py-2",
  ...rest
}) => {
  const allClassNames = `inline-edit-input ${className}`;

  const inputRef = useRef<HTMLInputElement>(null);

  const [editingValue, setEditingValue] = useState(value);

  useEffect(() => {
    if (value !== editingValue) {
      setEditingValue(value);
    }
  }, [value]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditingValue(event.target.value);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (event.key === "Enter" || event.key === "Escape") {
      target.blur();
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value.trim() === "" || event.target.value === value) {
      setEditingValue(value);
    } else {
      setValue({ [event.target.name]: event.target.value });
    }
    inputRef.current?.classList.remove(activeVerticalPadding);
  };

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    inputRef.current?.classList.add(activeVerticalPadding);
  };

  return (
    <input
      name={name}
      ref={inputRef}
      className={allClassNames}
      style={style}
      {...rest}
      type="text"
      aria-label="Field name"
      value={editingValue}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};
