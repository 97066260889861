import { ElementWrapper } from "../../../components/ElementWrapper";
import { IElement } from "../../../models/Element";
import { MarginType } from "../../utils";
import { MarginTopElement } from "./MarginTopElement";

type MarginTopElementFCProps = {
	index: number;
	data: MarginTopElement;
	updateData: (element: IElement, index: number) => void;
};

export const MarginTopElementFC = ({
	index,
	data,
	updateData,
}: MarginTopElementFCProps) => {
	const update = (fieldsToUpdate: Partial<MarginTopElement>) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		updateData(updatedData, index);
	};

	return (
		<ElementWrapper elementIndex={index}>
			<section className={`${data.name} w-100 py-6 px-4 border border-3 border-gray-200 rounded-3`}>
				<select
					className="form-select form-select-lg form-select-solid text-capitalize"
					data-control="select2"
					data-placeholder="Select Status..."
					value={data.unit}
					onChange={(e) =>
						update({
							unit: e.target.value,
						})
					}
				>
					<option
						value={MarginType.MARGIN_10}
					>{`Margin Top Element = ${MarginType.MARGIN_10}`}</option>
					<option
						value={MarginType.MARGIN_15}
					>{`Margin Top Element = ${MarginType.MARGIN_15}`}</option>
					<option
						value={MarginType.MARGIN_20}
					>{`Margin Top Element = ${MarginType.MARGIN_20}`}</option>
					<option
						value={MarginType.MARGIN_25}
					>{`Margin Top Element = ${MarginType.MARGIN_25}`}</option>
					<option
						value={MarginType.MARGIN_30}
					>{`Margin Top Element = ${MarginType.MARGIN_30}`}</option>
					<option
						value={MarginType.MARGIN_35}
					>{`Margin Top Element = ${MarginType.MARGIN_35}`}</option>
					<option
						value={MarginType.MARGIN_40}
					>{`Margin Top Element = ${MarginType.MARGIN_40}`}</option>
				</select>
			</section>
		</ElementWrapper>
	);
};
