export interface ITag {
	id: number;
	name: string;
	description: string;
	color: string;
}

export const defaultTag: ITag = {
	id: 0,
	name: "",
	description: "",
	color: "#000000",
};
