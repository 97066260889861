import { Redirect, Route, Switch } from "react-router-dom";
import { PageTitle } from "../../../_start/layout/core";
import { SeoSettingsPage } from "./pages/SeoSettingsPage";

export const SettingsPage = () => {
	return (
		<Switch>
			{/* begin::SEO ROUTES */}
			<Route exact={true} path="/settings/seo">
				<>
					<PageTitle>SEO</PageTitle>
					<SeoSettingsPage />
				</>
			</Route>
			{/* end::SEO ROUTES */}

			<Redirect
				from="/settings"
				exact={true}
				to="/settings/seo"
			/>
			<Redirect to="/settings/seo" />
		</Switch>
	);
};
