import { PageDataContainer, PageLink } from "../../../_start/layout/core";
import { postManagementSubmenu } from "./PostManagementData";
import { PostManagementPage } from "./PostManagementPage";

const breadcrumbs: Array<PageLink> = [
	{
		title: "Home",
		path: "/",
		isActive: false,
	},
	{
		title: "Berichtbeheer",
		path: "/post-management",
		isActive: false,
	},
];

export const PostManagementWrapper = () => {
	return (
		<>
			<PageDataContainer
				breadcrumbs={breadcrumbs}
				submenu={postManagementSubmenu}
			/>
			<PostManagementPage />
		</>
	);
};
