/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import {
  failureToast,
  handleGenerateSitemap,
  successToast,
} from '../../../support/utils';
import {
  defaultSetting,
  ISetting,
  TitleSeparatorType,
} from '../models/Setting';
import { updateSetting, viewSetting } from '../redux/SettingsCRUD';

export const SeoSettingsPage = () => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<ISetting>(defaultSetting);

  const updateData = (fieldsToUpdate: Partial<ISetting>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    try {
      await updateSetting(data);
      successToast('Seo settings has been updated.');
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const handleFetch = async () => {
    setLoading(true);

    try {
      const result = await viewSetting(1);
      setData(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">SEO Settings</h3>
        <div className="card-toolbar">
          <a
            className="btn btn-sm btn-light-info"
            onClick={() => handleGenerateSitemap(loading, setLoading)}
          >
            Generate Sitemap
          </a>
        </div>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Title Separator</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid text-capitalize"
                data-control="select2"
                data-placeholder="Select Status..."
                value={data.title_separator}
                onChange={(e) =>
                  updateData({
                    title_separator: e.target.value as TitleSeparatorType,
                  })
                }
              >
                <option value={TitleSeparatorType.DASH}>
                  {TitleSeparatorType.DASH}
                </option>
                <option value={TitleSeparatorType.EN_DASH}>
                  {TitleSeparatorType.EN_DASH}
                </option>
                <option value={TitleSeparatorType.EM_DASH}>
                  {TitleSeparatorType.EM_DASH}
                </option>
                <option value={TitleSeparatorType.COLON}>
                  {TitleSeparatorType.COLON}
                </option>
                <option value={TitleSeparatorType.SMALL_BULLET}>
                  {TitleSeparatorType.SMALL_BULLET}
                </option>
                <option value={TitleSeparatorType.MEDIUM_BULLET}>
                  {TitleSeparatorType.MEDIUM_BULLET}
                </option>
                <option value={TitleSeparatorType.ASTERISK}>
                  {TitleSeparatorType.ASTERISK}
                </option>
                <option value={TitleSeparatorType.STAR}>
                  {TitleSeparatorType.STAR}
                </option>
                <option value={TitleSeparatorType.BAR}>
                  {TitleSeparatorType.BAR}
                </option>
                <option value={TitleSeparatorType.TILDE}>
                  {TitleSeparatorType.TILDE}
                </option>
                <option value={TitleSeparatorType.DOUBLE_LEFT_ARROW}>
                  {TitleSeparatorType.DOUBLE_LEFT_ARROW}
                </option>
                <option value={TitleSeparatorType.DOUBLE_RIGHT_ARROW}>
                  {TitleSeparatorType.DOUBLE_RIGHT_ARROW}
                </option>
                <option value={TitleSeparatorType.LEFT_ARROW}>
                  {TitleSeparatorType.LEFT_ARROW}
                </option>
                <option value={TitleSeparatorType.RIGHT_ARROW}>
                  {TitleSeparatorType.RIGHT_ARROW}
                </option>
              </select>

              <div className="form-text">
                Select the symbol to use as your title separator. This will
                display, for instance between your post title and site name.
                Symbols are shown in the size they'll appear in the search
                results.
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              {/* <Link
								className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
								to={`/content-management/categories`}
							>
								Cancel
							</Link> */}
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
