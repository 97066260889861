import { ElementType, IElement } from "../../../models/Element";
import { ElementHeaderType } from "../../utils";

export type BlogContentDescription = IElement & {
  title: string;
  title_type: ElementHeaderType;
  content: string;
};

export const defaultBlogContentDescription: BlogContentDescription = {
  id: 0,
  name: ElementType.BLOG_CONTENT_DESCRIPTION,
  title: 'Zoekwoorden analyse',
  title_type: ElementHeaderType.H2,
  content:
    '<p>Wil je achterhalen welke keywords je gebruikt om te scoren in Google? Verplaats jezelf dan in de klant. Visualiseer jezelf, zoekende naar jouw product of dienst. Wat zoek je? Hoe zoek je? Wie zoek je? Ben je gericht aan het zoeken naar de dienst of het product? Of heb je misschien een probleem waar je een oplossing voor wil?</p><p>Om je doelgroep te bereiken zul je jezelf moeten verplaatsen in je doelgroep of hulp inschakelen en je omgeving aan de tand voelen. Wat is de insteek van hun zoekopdracht? Tegen welke problemen lopen ze aan? W aar hebben ze behoefte aan? Het levert ongetwijfeld verrassende inzichten op. Wanneer je kunt achterhalen welk probleem of welke behoefte je doelgroep heeft, dan hoef je alleen nog maar het antwoord op een presenteerblaadje aan te bieden. Er zijn een aantal tools die je kunt inzetten om te beoordelen welke keywords interessant zijn.</p><p>&nbsp;</p><h2>“Maar welke keywords moet ik dan precies gebruiken voor mijn webshop?”</h2>',
};
