import { IActionUnion, makePayloadAction } from "../../../../setup/redux/utils";
import { PaginatedValue } from "../../../support/utils";
import { IMedia } from "../models/Media";
import { MediaLibraryEvents } from "./MediaLibraryEvents";

export const setPaginatedValue = makePayloadAction<
	MediaLibraryEvents.SET_PAGINATED_VALUE,
	PaginatedValue<IMedia>
>(MediaLibraryEvents.SET_PAGINATED_VALUE);

export const setSearchPaginatedValue = makePayloadAction<
	MediaLibraryEvents.SET_SEARCH_PAGINATED_VALUE,
	PaginatedValue<IMedia>
>(MediaLibraryEvents.SET_SEARCH_PAGINATED_VALUE);

export const mediaLibraryActions = {
	setPaginatedValue,
	setSearchPaginatedValue,
};

export type MediaLibraryActions = IActionUnion<typeof mediaLibraryActions>;
