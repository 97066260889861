import { ElementType, IElement } from "../../../models/Element";
import { ElementHeaderType } from "../../utils";

export type BlogContentWideDescriptionTwoColumn = IElement & {
	title: string;
	title_type: ElementHeaderType;
	content: string;
};

export const defaultBlogContentWideDescriptionTwoColumn: BlogContentWideDescriptionTwoColumn =
	{
		id: 0,
		name: ElementType.BLOG_CONTENT_WIDE_DESCRIPTION_TWO_COLUMN,
		title: "Zoekwoorden bepalen",
		title_type: ElementHeaderType.H2,
		content:
			"<p>Oké, je hebt je verplaatst in je ideale klant en een aantal zoekwoorden opgeschreven. Vervolgens heb je deze zoekwoorden geanalyseerd in Google. What’s next?&nbsp;</p><p>Je hebt inmiddels een goed beeld van interessante zoekwoorden, maar welke zoekwoorden ga je gebruiken? Gebruik Google’s Keyword Planner of de Keyword Explorer van Ahrefs voor het zoekvolume op de zoekwoorden. Niet alleen krijg je inzicht in het aantal zoekopdrachten voor deze zoektermen, ook interessante alternatieven worden getoond.&nbsp;</p><p>Het ideale zoekwoord heeft een hoog zoekvolume en wordt door weinig concurrenten gebruikt. Is de concurrentie hoog op jouw zoekwoord? Dan is de kans kleiner dat je (snel) gaat scoren met dit zoekwoord. Het loont om te onderzoeken of er interessante variaties op jouw zoekwoord zijn.</p><p>Vergeet ook niet de zoekintentie van de bezoeker, die je hebt achterhaald in je analyse. Zoekopdrachten variëren van heel breed tot heel specifiek. Sommige zoekopdrachten bevinden zich mogelijk al op het punt waarop ze klaar zijn om een ​​product te kopen of gebruik te maken van een dienst.&nbsp;</p>",
	};
