import { ElementType, IElement } from "../../../models/Element";
import { ElementHeaderType } from "../../utils";

export type BlogContentHeading = IElement & {
	title: string;
	title_type: ElementHeaderType;
	description: string;
	description_type: ElementHeaderType;
	content: string;
};

export const defaultBlogContentHeading: BlogContentHeading = {
	id: 0,
	name: ElementType.BLOG_CONTENT_HEADING,
	title: "Je advertentie geweigerd? Facebook geeft inzcht in reviewprocess",
	title_type: ElementHeaderType.H1,
	description: "SEO voor webshops",
	description_type: ElementHeaderType.H2,
	content:
		"<p><strong>In het eerste kwartaal van 2021 zijn er ruim 1.000 webwinkels gestart. Wanneer deze trend doorzet zijn er in 2022 meer webshops zijn dan traditionele (non-food) winkels. Dit betekent letterlijk werk aan de winkel voor jou als online shopeigenaar, als je online zichtbaar wil zijn en blijven. Maar hoe zorg je ervoor dat je relevant blijft voor Google?&nbsp;</strong></p><p>Met de huidige concurrentie is het belangrijk om gezien te worden als een relevante bron van informatie. Jij bent immers de expert in jouw vakgebied. Toch wordt het vrij lastig als niemand deze expertise online kan terugvinden. Nog lastiger is het om business te genereren wanneer niemand je website bezoekt.</p><p>Hier komt het belang van keywords om de hoek kijken, want de keuze van je keywords kan je SEO strategie maken of breken. Hoewel het niet altijd makkelijk is om de juiste keywords te vinden om traffic naar je website te sturen, het werpt uiteindelijk altijd zijn vruchten af.</p>",
};
