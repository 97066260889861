import { PageLink } from "../../../_start/layout/core";

export const postManagementSubmenu: Array<PageLink> = [
	{
		title: "Berichten",
		path: "/post-management/posts",
		isActive: true,
	},
	{
		title: "Categorieën",
		path: "/post-management/categories",
		isActive: true,
	},
	{
		title: "Tags",
		path: "/post-management/tags",
		isActive: true,
	},
	{
		title: "FAQs",
		path: "/post-management/faqs",
		isActive: true,
	},
];
