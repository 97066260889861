import React from 'react';
import { KTSVG } from '../../../_start/helpers';

type DeleteModalProps = {
  headerTitle?: string;
  bodyMessage?: string;
  item: any;
  handleDelete: (item: any) => void;
};

export const DeleteModal: React.FC<DeleteModalProps> = ({
  headerTitle = 'Delete Item',
  bodyMessage = 'Are you sure to delete this item? This action is irreversible.',
  item,
  handleDelete,
  children,
}) => {
  return (
    <div className="modal fade" tabIndex={-1} id="custom_delete_kt_modal_1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{headerTitle}</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotone/Navigation/Close.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body">
            {children ? (
              children
            ) : (
              <>
                <p>
                  {bodyMessage}
                </p>
                <p className="block">{item.name}</p>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              onClick={() => handleDelete(item)}
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
