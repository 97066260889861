export interface ICategory {
	id: number;
	name: string;
	slug: string;
	description: string;
	color: string;
}

export const defaultCategory: ICategory = {
	id: 0,
	name: "",
	slug: "",
	description: "",
	color: "#000000",
};
