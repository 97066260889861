/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  getConfig,
  PageDataContainer,
  PageLink,
  useTheme,
} from "../../../_start/layout/core";
import { settingsSubmenu } from "./SettingsData";
import { SettingsPage } from "./SettingsPage";

const defaultPageConfig = getConfig();

const breadcrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/",
    isActive: false,
  },
  {
    title: "Instellingen",
    path: "/settings",
    isActive: false,
  },
];

export const SettingsWrapper = () => {
  const { setTheme } = useTheme();

  // Refresh UI after config updates
  useEffect(() => {
    return () => {
      setTheme(defaultPageConfig);
    };
  }, []);

  return (
    <>
      <PageDataContainer
        breadcrumbs={breadcrumbs}
        submenu={settingsSubmenu}
      />
      <SettingsPage />
    </>
  );
};
