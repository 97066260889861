import { ElementType, IElement } from "../../../models/Element";

export type BlogContentInfoPanel = IElement & {
	content: string;
};

export const defaultBlogContentInfoPanel: BlogContentInfoPanel = {
	id: 0,
	name: ElementType.BLOG_CONTENT_INFO_PANEL,
	content:
		"<p>Zoek ik op 'damesjurk', dan ben ik eigenlijk aan het rondkijken en<br>inspiratie aan het opdoen. Zoek ik op ‘groene damesjurk voor<br>lentehuwelijk’, dan heb ik een huwelijk in het verschiet waar ik een<br>mooie jurk wil dragen.</p><p>Het volume op ‘groene damesjurk voor lentehuwelijk’ is een stuk lager dan<br>op ‘damesjurk’. De koopintentie is echter een stuk hoger op deze longtail<br>zoekwoorden. Het gebruik van long-tail zoekwoorden kan dus strategisch heel<br>interessant zijn. Hoewel het zoekvolume lager is kunnen ze je juist helpen een<br>specifieke doelgroep voor jouw niche te targeten. Mits het zoekwoord relevant is<br>voor jouw niche- en merk.&nbsp;</p>",
};
