import { ElementType, IElement } from "../../../models/Element";

export type BlogContentImage = IElement & {
  image: string;
  image_alt: string;
  image_caption: string;
};

export const defaultBlogContentImage: BlogContentImage = {
  id: 0,
  name: ElementType.BLOG_CONTENT_IMAGE,
  image: '',
  image_alt: '',
  image_caption: 'Enter image caption',
};
