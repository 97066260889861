import { ElementType, IElement } from "../../../models/Element";
import { ElementHeaderType } from "../../utils";

export type BlogContentDescriptionWithInfo = IElement & {
	title: string;
	title_type: ElementHeaderType;
	content: string;
	content_info: string;
};

export const defaultBlogContentDescriptionWithInfo: BlogContentDescriptionWithInfo =
	{
		id: 0,
		name: ElementType.BLOG_CONTENT_DESCRIPTION_WITH_INFO,
		title: "Keyword tool",
		title_type: ElementHeaderType.H2,
		content:
			"<p>SERP staat voor&nbsp; Search Engine Results Page (de resultatenpagina van een zoekmachine). SERP Analyse tools vertellen je de top-ranking pagina’s voor een keyword. Wanneer je een SERP analyse uitvoert voor een keyword let je in ieder geval op de zoekintentie en de relevantie van je zoekwoord en bekijk je de concurrentie op basis van dat zoekwoord.&nbsp;</p>",
		content_info:
			"<p><strong>Voorbeeld:</strong> wanneer iemand zoekt op ‘chocolade verwarmen’ is de intentie van deze persoon waarschijnlijk niet het kopen van een reep chocolade. Artikelen die hoog scoren zijn artikelen die je vertellen hoe je chocolade het beste kunt verwarmen.&nbsp;</p>",
	};
